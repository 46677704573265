import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Form from "../views/Form";
import Thanks from "../views/Thanks"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: 'Three6five Job Portal',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
      ]
    }
  },
  {
    path: "/form",
    name: "form",
    component: Form,
    props: true,
    meta: {
      title: 'Three6five Job Portal'
    }
  },
  {
    path: "/thanks",
    name: "thanks",
    component: Thanks,
    meta: {
      title: 'Three6five Job Portal'
    }
  },
];

const router = new VueRouter({
  routes
});

export default router;
