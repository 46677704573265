<template >
  <div class = "t6fprimary">
    <br><br>
    <router-view />
  </div>
</template>


<script>

//import "@/custom css/material-kit.css"
export default {
  data () {
    return {
      backgroundStyle: {
          backgroundImage: "url(https://crux.t6f.co.za/bitrix/templates/t6f_template/images/mb-back.jpg)"
      },
    }
  }
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


.t6fpadded {
  padding: 20px 400px 500px 400px;
}

.t6fprimary {
  background-image: url(https://crux.t6f.co.za/bitrix/templates/t6f_template/images/mb-back.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  font-family: Montse, Arial, Times, serif;
  font-size: 105%;
  font-weight: normal;
  color: white;
}


.t6ftransparentbg {
  background-color: rgba(33, 26, 26, 0.85);
  padding: 30px 50px 30px 50px;
  border-radius: 15px;
  border-style: groove;
  border-width: thin;
  border-color: #e4e8ef;
}

.centered {
  text-align: center;

}
@media screen and (max-width: 900px) {
 .t6fpadded {
  padding: 4px 10px 10px 10px;
}
}
</style>
