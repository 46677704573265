<template>
  <div>
    <div class="t6fpadded">
      <template v-if="hasReceivedRoles">
        <br><br>
        <div class="t6ftransparentbg">
          <h5>Welcome!</h5>
          <br>
          <p>Please select the role you would like to apply for.</p>

          <vue-form-generator @validated="customValidation" :schema="rolesSchema" :model="rolesModel" :options="formOptions"></vue-form-generator>
          <p v-html="activeRoleDescription"></p>
          <br><br>
          <template v-if="roleIsValid ">
            <b-button v-on:click="LoadFormPage()" variant="primary">
              Apply now
            </b-button>
          </template>
          <template v-else>
            <b-button disabled v-on:click="LoadFormPage()" variant="primary">
              Apply now
            </b-button>
          </template>

        </div>
      </template>

      <template v-else >
        <div class="centered">
          <b-spinner style="width: 5rem; height: 5rem;" variant="secondary" label="Loading..."></b-spinner>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
import Vue from "vue";
import VueFormGenerator from "vue-form-generator";
import "vue-form-generator/dist/vfg.css";

Vue.use(VueFormGenerator);

export default {
  title: 'three6five careers',
  data() {
    return {
      activeRoleDescription: "",
      roleDescriptions: [],
      hasReceivedRoles: false,
      formIsValid: false,
      roleIsValid: false,
      rolesModel: {
        role: null
      },
      rolesSchema: null,
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      },
      baseUrl: "https://jobportalmanager.three6five.co.za",
      //baseUrl: "http://127.0.0.1:8000"
    };
  },
  methods: {
    customValidation: function () {
      this.roleIsValid = this.rolesModel.role != null;
      if (this.roleIsValid)
          this.setActiveRoleDescription(this.rolesModel.role)
    },
    LoadFormPage: function () {
      let roleToGet = this.rolesModel.role
      this.$router.push({name: 'form', params: { roleToGet: roleToGet}})
    },
    retrieveRoles: function () {
      console.log('baseUrl = '  + this.baseUrl)
      this.axios.get(this.baseUrl + "/api/retrieve/roles").then((response) => {
        this.hasReceivedRoles = true
        this.rolesSchema = response.data
      })
    },
    retrieveRoleDescriptions: function () {
      this.axios.get(this.baseUrl + "/api/retrieve/roleDescriptions").then((response) => {
        this.roleDescriptions = response.data
      })
    },
    setActiveRoleDescription: function (role) {
      this.activeRoleDescription = this.roleDescriptions[role]
    },
  },
  mounted() {
    this.retrieveRoles()
    this.retrieveRoleDescriptions()
  }
};
</script>

<style>

</style>