<template>
  <div class="t6fpadded">
    <div class="t6ftransparentbg">
      <div class="centered">
        <h2>Thank you for your application!</h2>
        <br>
        <p>
          If you haven't heard back from us within 2 weeks, please consider your application unsuccessful.
        </p>
      </div>
      <br>
    </div>
  </div>
</template>

<script>

export default {
  data() { return {}

  },
  methods: {


  },
  mounted() {

  }

}

</script>