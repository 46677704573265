<template xmlns:border-style="http://www.w3.org/1999/xhtml">
  <div class="t6fpadded">
    <div class="t6ftransparentbg">
      <b-button v-on:click="goBack">Back</b-button>
      <br><br>
      <h2 class="text-center">{{roleToGet}}</h2>
      <br>
      <p>Please fill out the information below and attach a profile photo of yourself as well as your CV and anything else you feel is relevant to your application.</p>
      <br>
    </div>
    <br><br>
    <div class="t6ftransparentbg">
      <h4>Personal details</h4>
      <br>

      <template>
        <vue-form-generator @validated="onValidatedMain" :schema="personalDetailsSchema" :model="personalDetailsModel" :options="formOptions"></vue-form-generator>
      </template>

      <br>
      <hr>
      <br>

    </div>
    <br><br>

    <div class="t6ftransparentbg">
    <h4>Related Questions</h4>
    <br>

    <template>
      <vue-form-generator @validated="onValidatedMain" :schema="additionalQuestionsSchema" :model="additionalQuestionsModel" :options="formOptions"></vue-form-generator>
    </template>

    <br>
    <hr>
    <br>

    </div>

    <br><br>
    <div class="t6ftransparentbg">
      <h4>Related Experience</h4>
      <br>

      <div v-for="(qualification, myIndex) in experienceModelSet" :key="myIndex">
        <vue-form-generator @validated="onValidatedMain" :schema="experienceBaseSchema" :model="experienceModelSet[myIndex]" :options="formOptions"></vue-form-generator>
        <br>
      </div>

      <template>
        <b-button variant="primary" v-on:click="incrementExperience" >+</b-button> &nbsp;
        <b-button variant="primary" v-on:click="decrementExperience" >-</b-button>
      </template>

      <br><hr><br>
    </div>
    <br><br>
    <div class="t6ftransparentbg">
      <h4>Qualifications</h4>
      <br>

      <div v-for="(qualification, myIndex) in qualificationModelSet" :key="myIndex">
        <vue-form-generator @validated="onValidatedMain" :schema="qualificationBaseSchema" :model="qualificationModelSet[myIndex]" :options="formOptions"></vue-form-generator>
        <br>
      </div>


      <template >
        <b-button variant="primary" v-on:click="incrementQualifications" >+</b-button> &nbsp;
        <b-button variant="primary" v-on:click="decrementQualifications" >-</b-button>
      </template>

    </div>
    <br><br>
    <div class="t6ftransparentbg">
      <h4>Supporting files</h4>
      <p>Do not exceed 5 MB's per file you wish to upload</p>
      <br>
      Please upload your profile picture
      <b-form-file
          v-model="file1"
          :state="Boolean(file1)"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
      ></b-form-file>
      <br><br>
      Please upload your CV
      <b-form-file
          v-model="file2"
          :state="Boolean(file2)"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
      ></b-form-file>
      <br><br>
      Please upload anything else you'd like us to see (optional)
      <b-form-file
          v-model="file3"
          :state="Boolean(file3)"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
      ></b-form-file>
      <br><br>
    </div>
    <br><br>
      <div class="t6ftransparentbg">
        <template v-if="formIsValid" >
          <template v-if="awaitingFormCompletion">
            <div class="centered">
              <p>Uploading... this may take a few minutes depending on the size of the files you're uploading...</p>
              <b-spinner style="width: 3rem; height: 3rem;" variant="primary" label="Awaiting form submission success..."></b-spinner>
            </div>
          </template>
          <template v-else>
            <b-button variant="primary" v-on:click="submitForm" >Submit Application</b-button>
          </template>
        </template>

        <template v-else>
          <b-button disabled variant="secondary"> Submit Application</b-button>
        </template>
      </div>
    </div>
</template>

<script>
import "@/custom css/forms.css"
//import VueFormGenerator from "vue-form-generator";

export default {
  props: ['roleToGet'],
    data() { return {
      dataId: 0,
      file1: null,
      file2: null,
      file3: null,
      qualificationBaseSchema:  null,
      qualificationBaseModel:  null,
      experienceBaseSchema: {},
      experienceBaseModel: null,
      qualificationModelSet: [],
      experienceModelSet: [],
      formIsValid: false,
      receivedExperienceSetModel: null,
      roleDescriptions: null,
      personalDetailsSchema: null,
      personalDetailsModel: null,
      additionalQuestionsSchema: null,
      additionalQuestionsModel: null,
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
      baseUrl: "https://jobportalmanager.three6five.co.za",
      //baseUrl: "http://127.0.0.1:8000",
      awaitingFormCompletion: false,
    }
  },
  methods: {
    uploadFiles: function (folderId) {
      var reader = new FileReader()
      if (this.file1 != null) {
        reader = new FileReader()
        reader.readAsDataURL(this.file1)
        reader.onload =  () => {
          let fileData = reader.result
          this.axios({
            method: 'post',
            url: this.baseUrl + "/api/upload/" + folderId,
            headers: {},
            data: {
              'filename': this.file1.name,
              'data': fileData
            }
          }).then(() => {
            if (this.file2 != null) {
              reader = new FileReader()
              reader.readAsDataURL(this.file2)
              reader.onload =  () => {
                let fileData = reader.result
                this.axios({
                  method: 'post',
                  url: this.baseUrl + "/api/upload/" + folderId,
                  headers: {},
                  data: {
                    'filename': this.file2.name,
                    'data': fileData
                  }
                }).then(() => {
                  if (this.file3 != null) {
                    reader = new FileReader()
                    reader.readAsDataURL(this.file3)
                    reader.onload =  () => {
                      let fileData = reader.result
                      this.axios({
                        method: 'post',
                        url: this.baseUrl + "/api/upload/" + folderId,
                        headers: {},
                        data: {
                          'filename': this.file3.name,
                          'data': fileData
                        }
                      }).then(() => {

                      })
                    }
                  }
                })
              }
            }
          })
        }
      }
    },
    goBack: function () {
      this.$router.go(-1)
    },
    onValidatedMain: function (isValid) {
      this.formIsValid = isValid
    },
    retrievePersonalDetailsSchema: function () {
      this.axios.get(this.baseUrl + "/api/retrieve/personalDetailsSchema/" + this.roleToGet).then((response) => {
        this.personalDetailsSchema = response.data
      })
    },
    retrievePersonalDetailsModel: function() {
      this.axios.get(this.baseUrl + "/api/retrieve/personalDetailsModel/" + this.roleToGet).then((response) => {
        this.personalDetailsModel = response.data.fields
      })
    },
    retrieveAdditionalQuestionsSchema: function () {
      this.axios.get(this.baseUrl + "/api/retrieve/additionalQuestionsSchema/" + this.roleToGet).then((response) => {
        this.additionalQuestionsSchema = response.data
      })
    },
    retrieveAdditionalQuestionsModel: function() {
      this.axios.get(this.baseUrl + "/api/retrieve/additionalQuestionsModel/" + this.roleToGet).then((response) => {
        this.additionalQuestionsModel = response.data.fields
      })
    },
    retrieveBaseTemplateData: function (){
      this.axios.get(this.baseUrl + "/api/retrieve/baseSchema/experience/" + this.roleToGet).then((response) => {
        this.experienceBaseSchema = response.data.fields

        this.axios.get(this.baseUrl + "/api/retrieve/baseModel/experience/" + this.roleToGet).then((response) => {
          this.experienceBaseModel = response.data.fields
          this.buildSet('experience')

          this.axios.get(this.baseUrl + "/api/retrieve/baseSchema/qualification/" + this.roleToGet).then((response) => {
            this.qualificationBaseSchema = response.data.fields

            this.axios.get(this.baseUrl + "/api/retrieve/baseModel/qualification/" + this.roleToGet).then((response) => {
              this.qualificationBaseModel = response.data.fields
              this.buildSet('qualifications')
            })
          })
        })
      })
    },
    incrementQualifications: function () {

      this.addToSet('qualifications')
    },
    decrementQualifications: function () {

      if (this.qualificationModelSet.length > 1)
      {
        this.removeFromSet('qualifications')
      }
    },
    incrementExperience: function () {
      this.addToSet('experience')
    },
    decrementExperience: function () {
      if (this.experienceModelSet.length > 1)
      {
        this.removeFromSet('experience')
      }
    },
    buildSet:  function (setType) {
      if (setType === "experience")
      {
        this.experienceModelSet = []
        let targetB = {}
        let newModel = Object.assign(targetB, this.experienceBaseModel)
        this.experienceModelSet.push(newModel)
      }
      else if (setType === 'qualifications')
      {
        this.qualificationModelSet = []
        let targetB = {}
        let newModel = Object.assign(targetB, this.qualificationBaseModel)
        this.qualificationModelSet.push(newModel)
      }
      else
      {
        console.error("Invalid set type passed to builder")
      }
    },
    addToSet: function (setType) {
      if (setType === "experience")
      {
        let targetB = {}
        let newModelB = Object.assign(targetB, this.experienceBaseModel)
        this.experienceModelSet.push(newModelB)
      }
      else if (setType === 'qualifications')
      {
        let targetB = {}
        let newModelB = Object.assign(targetB, this.qualificationBaseModel)
        this.qualificationModelSet.push(newModelB)
      }
      else
      {
        console.error("Invalid set type passed to pusher")
      }
    },
    removeFromSet: function (setType) {
      if (setType === "experience")
      {
        this.experienceModelSet.pop()
      }
      else if (setType === 'qualifications')
      {
        this.qualificationModelSet.pop()
      }
      else
      {
        console.error("Invalid set type passed to popper")
      }
    },

    submitForm: function () {

      this.personalDetailsModel['role'] = this.roleToGet

      let allFields = {}
      for (let key in this.personalDetailsModel) //Add base model
      {
        allFields[key] = this.personalDetailsModel[key]
      }

      for (let key in this.additionalQuestionsModel) //Add base model
      {
        allFields[key] = this.additionalQuestionsModel[key]
      }

      for (let dict in this.experienceModelSet) //Add experience
      {
        for (let key in this.experienceModelSet[dict])
        {
          let num = parseInt(dict) + 1
          allFields[key + num]= this.experienceModelSet[dict][key]
        }
      }

      for (let item in this.qualificationModelSet) //Add qualifications
      {
        for (let key in this.qualificationModelSet[item])
        {
          let num = parseInt(item) + 1
          allFields[key + num] = this.qualificationModelSet[item][key]
        }
      }

      this.awaitingFormCompletion = true;
      this.axios({method: 'post',
        url: this.baseUrl + "/api/submit",
        headers: {},
        data: allFields
      }).then((response) => {
        let folderId = response.data.folderId
        this.uploadFiles(folderId)
        this.$router.push({name: 'thanks'})
        this.awaitingFormCompletion = false;
      })
    },
  },
  mounted() {
    this.retrievePersonalDetailsSchema()
    this.retrievePersonalDetailsModel()
    this.retrieveAdditionalQuestionsSchema()
    this.retrieveAdditionalQuestionsModel()
    this.retrieveBaseTemplateData()
  }

}

</script>

<style>

  .padded {
    padding: 50px 50px 50px 50px;
    border-style: groove;
    border-width: medium;
    border-color: #e4e8ef;
    mso-border-shadow: yes;
    border-radius: 10px;
  }

  .hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 50px;
    border: 0;
    border-top: 5px solid rgba(0.5, 0.5, 0.5, 0.5);
  }

</style>